@import "../../styles/variables";

.subscription-page {
    .subscription-container {
        padding: 12px 20px !important;
        font-size: 14px !important;

        // Título do container
        .subscription-title {
            font-size: 13px !important;
        }

        // Div de itens customizado
        .subscription-item {
            display: flex;
            padding: 0px 0px 12px 0px;
            width: 100%;

            // Propriedades opcionais adicionais dos itens
            &.alignitems-center {
                align-items: center;
            }

            &.justifycontent-flexend {
                justify-content: flex-end;
            }

            &.justifycontent-spacebetween {
                justify-content: space-between;
            }

            &.flexdirection-column {
                flex-direction: column !important;
            }


            // Icone
            .k-icon {
                font-size: 20px;
                margin-right: 12px;
            }

            // Botão de renovar assinatura
            .button-renovarassinatura {
                padding: 6px 12px;
                font-size: 13px;
            }


            // Linhas de Histórico de Pagamento
            .subscription-payment {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 8px 12px;

                // Icone do Pagamento
                .k-icon {
                    font-size: 20px;
                    margin-right: 16px;
                }

                // Descrição e Data
                .subscription-payment-text {
                    display: flex;
                    align-items: center;

                    // Descrição (evitar margem entre os elementos)
                    & > span > p {
                        margin: 0px;
                    }

                    // Data
                    & > span > .subscription-payment-date {
                        font-size: 12px;
                    }

                }
                // Download RPS
                .subscription-payment-rps{
                    text-decoration: none;
                }

                // Zebrar lista
                &:nth-child(2n+1) {
                    background-color: rgba(0, 0, 0, 0.05);
                }
            }
            
        }
        div.is-disabled
        {
        pointer-events: none;
        opacity: 0.7;
        }
        .subscription-fields-footer{
            display: flex;
            padding: 1%;
            .subscription-missingInformation{
                
                display: flex;
                flex-direction: column;
                font-size: 12px;
                color: red;
                i{
                    padding-right: 8px;
                }
                button{
                    background-color: transparent;
                    display: flex;
                    justify-content: flex-start;
                    padding: 0;
                    border: 0;

                    b{
                        color: red;
                        font-size: 12px;
                        text-decoration: underline;
                    }
                }
               
            }
        }
    }

    .divider {
        width: calc(100% - 40px);
    }
}

.dialog-subsc{
    width: 600px;
    padding: 30px;
}
// Dialog de exibição do histórico de pagamentos
.paymenthistory-dialog {
 


    // Titulo do Dialog
    .k-dialog-titlebar {
        background-color: #fff;
        color: #000;
    }

    // Rodapé do Dialog
    .k-justify-content-stretch {
        justify-content: flex-end;

        & > * {
            flex: unset;
        }
    }


    // Caixa de filtros
    .paymenthistory-filter {
   
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & > * {
            width: 160px;
            margin-right: 8px;
        }
    }

    .divider {
        margin: 16px 0px;
        background-color: rgba(0, 0, 0, 0.05);
    }

    .paymenthistory-table {
        max-height: 400px;
        overflow: auto;

        > table {
            width: 100%;

            .k-icon {
                font-size: 20px;
            }

            > thead {
                text-align: left;
                position: sticky;
                top: 0;
                background-color: #fff;
            

                th {
                    padding: 12px 8px;
                }
            }

            > tbody > tr {
                > td {
                    padding: 8px;
                }

                &:nth-child(2n+1) {
                    background-color: rgba(0, 0, 0, 0.05);
                }
            }
        }
    }
    //Download RPS
    .history-subscription-payment-rps{
        text-decoration: none;

    }
}

.payment-missing-info-alert {
    text-align: justify;
}

.text-align-center {
    text-align: center;
}

.btn-search-payment{
    background: #003348;
    color: #d9e255;
    flex-direction: row;
 font-size: medium;
}
.btn-close-payment{
    background: #003348;
    color: #d9e255;
    flex-direction: row;
 font-size: small;
}
.btn-secondary {
    background: $secondary;
    color: #fff;
}
.btn-secondary:hover {
    background-color: #ebebeb;
    color: #333;
}

.tooltipnfse{
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    position: relative;
    font-size: small;
}

.tooltipnfse::before,
.tooltipnfse::after {
  --scale: 0;
  --arrow-size: 5px;
  --tooltip-color: #00263c;
  position: absolute;
  top: -.25rem;
  left: 50%;
  transform: translateX(-40%) translateY(var(--translate-y, 0)) scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}

.tooltipnfse::before {
  --translate-y: calc(-80% - var(--arrow-size));
  content: attr(data-tooltipnfse);
  color: white;
  padding: .5rem;
  border-radius: .3rem;
  text-align: center;
  background: var(--tooltip-color);
}

.tooltipnfse:hover::before,
.tooltipnfse:hover::after {
  --scale: 0.8;
}

.tooltipnfse::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: '';
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}
