@import '../../styles/variables';

.channel-select-page {
    .channel-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 24px;
        max-width: 100%;
        max-height: 80vh;
        overflow: auto;

    }
    .channel-item {
        margin: 18px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;

        .avatar {
            font-size: 32px;
            font-weight: bold;
            background-color: $primary;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin-bottom: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .label {
            font-size: 18px;
            color: $primary;
            font-weight: bold;
            text-align: center;
        }
    }
    
}