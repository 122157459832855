.dropdown-container {
    display: flex;
    align-items: center;
    color: #000;
    cursor: pointer;

    .menu-label {
        font-size: 14px;
        color: #000;
    }
}

.popup-content {
    padding: 0px;
    color: #fff;
    background-color: #333;
    border: 1px solid rgba(0,0,0,.05);
    border-radius: 2px;
}