@import '../../styles/variables';

.text-info {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 12px 20px !important;



    .caption {
        font-size: 15px;
    }

    .k-checkbox + .k-checkbox-label, .k-checkbox-label + .k-checkbox {
        margin-left: 8px;
    }

    .k-button {
        padding: 8px 20px;
    }

    .cursor-pointer {
        cursor: pointer;
    }
}