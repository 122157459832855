@import '../../styles/variables';

.footer-container {
    height: $footer-height;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    text-align: center;
    font-size: 12px;
}