

.formGrid {
    display: flex;
    width: 100%
}

.divider {
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
}

.button-positions {
    display: flex;
    justify-content: center;
    Button:first-child{
        margin-right: 10px;
    }
}

.endereco-view {
    overflow: auto;
    white-space: nowrap;
    padding: 6px;
}

.endereco-page {
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
        background: #00263c;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #00263c;
    }
    input:disabled{
        background: #ddd;
    }
}

.endereco-view li {
    display: inline-block;
    width: 260px;
    max-width: 244px;
    height: 70px;
    border: 1px solid #00263c;
    outline: 2px transparent;
    margin-right: 4px;
    padding: 10px 8px;
    position: relative;
    &:hover {
        background-color: #d9e255;
    }
    &.endereco-view-selected {
        background-color: #d9e255;
        outline: 2px solid #00263c;
    }
}

.endereco-view-line {
    display: flex;
    max-width: inherit;
}

.endereco-view-nomes {
    max-width: inherit;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
}

.endereco-view-cidade {
    white-space: nowrap;
    max-width: calc(75%);
    text-overflow: ellipsis;
    overflow-x: hidden;
}

.endereco-view-uf {
    white-space: pre;
}

.endereco-view-actions {
    position: absolute;
    right: 2px;
    bottom: 2px;
}

.endereco-view-actionButton {
    background-color: transparent;
    border: 0px;
    &:hover {
        background-color: transparent;
    }
    &:focus {
        box-shadow: 0 0 0 0px;
    }
}

.endereco-view-addButton {
    span.k-icon {
        color: #d9e255;
        font-size: medium;
        font-weight: 100;
    }
}

.tooltip {
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    position: relative;
  }
  
  .tooltip::before,
  .tooltip::after {
    --scale: 0;
    --arrow-size: 20px;
    --tooltip-color: #00263c;
    position: absolute;
    top: -.25rem;
    left: 50%;
    transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
    transition: 150ms transform;
    transform-origin: bottom center;
  }
  
  .tooltip::before {
    --translate-y: calc(-100% - var(--arrow-size));
    content: attr(data-tooltip);
    color: white;
    padding: .5rem;
    border-radius: .3rem;
    text-align: center;
    width: max-content;
    background: var(--tooltip-color);
  }
  
  .tooltip:hover::before,
  .tooltip:hover::after {
    --scale: 1;
  }
  
  .tooltip::after {
    --translate-y: calc(-1 * var(--arrow-size));
    content: '';
    border: var(--arrow-size) solid transparent;
    border-top-color: var(--tooltip-color);
    transform-origin: top center;
  }

@import '../../styles/variables';