

.containerSearch {
    margin-left: 1em;
    margin-top: 8em;
    display: flex;
    flex-direction: row;
    font-size: medium;
    align-items: center;
    justify-content: center;
    position: relative;
}

.textBoxEmail, .textBoxUser {
    margin-right: 1em;
    font-size: medium; 
    width: 100%; 
}

.btnSearch {
    margin-top: 1.4em;
    flex-direction: row;
    font-size: medium;
    width: 60%; 
}

.formUserSelect {
    width: 720px !important;
}


@import '../../styles/variables';