.main-modal-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 520px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    .title-container {
        width: 87%;
        background-color: #333333;
        padding: 12px;
        color: #ffffff
    }

    .title-square-detail {
        width: 13%;
        background-color: #d9e255;
    }

    .main-modal-header {
        font-family: 'Leelawadee UI', Georgia, 'Times New Roman', Times, serif;
        font-size: 1.5rem;
    }

    .main-modal-content {
        background-color: rgba(255, 255, 255,0.8);
        padding: 0 14px 14px 14px;
    }
}
