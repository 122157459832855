@import '../../styles/variables';

$padding-container: 12px;
$container-height: $app-bar-height;
$logo-size: $container-height - $padding-container;
$logo-size: $container-height - $padding-container;

.app-bar-container {
    height: $container-height;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 1px 1px;
    display: flex;
    align-items: center;
    padding: $padding-container;
   

    .logo-container {
        width: 50%;
        margin:0 auto;
    }

    .channel-logo {
        /* width: $logo-size; */
        height: $logo-size;
        object-fit: contain;
        cursor: pointer;
    }
}

.flex-start {
    justify-content: flex-start;
}

.flex-end {
    justify-content: flex-end;
}

.menu-items-container {
    display: flex;
    align-items: center;
  width: 100%;

    .menu-item {
        margin-left: 12px;
    }
}

.options-container {
    margin: 8px 0 !important;
    padding: 0 !important;
    list-style: none !important;

    li {
        padding: 4px 24px;
        cursor: pointer;
        transition: background-color 0.8s, color 0.8s;

    }

    .item:hover {
        background-color: $primary;
        color: #fff;
    }

    .selected {
        background-color: $primary;
        color: #fff;
    }
}

.subs-options{
    color: black;
    display: flex;
    align-items: center;
 font-size: x-small;

    span{
      
        float: left;
        padding-top: 28%; 
    }
}
.dropDownMenuUser{
    position: absolute;
}

@media only screen and (max-width: 600px) {
    .menu-label {
        display: none;
    }
}