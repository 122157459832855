@import '../../styles/variables';

.combobox-custom {
    input {
        border: 0px;
        outline: 0px;
        width: inherit;
    }
    button {
        background-color: transparent;
        border: 0px;
        width: 20px;
        height: 20px;
    }
}

.combobox-popup-custom {
    ul {
        list-style-type: none;
        padding: 0px;
    }
    li {
        padding-left: 20px;
        &:hover {
            background-color: #ebebeb;
            cursor: pointer;
        }
    }
}