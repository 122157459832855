
.tableUser{
    margin-top: 0.8em;
    font-size: small;
    padding: 15px;
    width: 100%;

    thead{
        background: #003348;
        color: #d9e255;
        text-align: center;
    }
    th{
        padding: 0.8em;
        font-weight:normal;
        text-align: center;
    }
    tbody{
        background: rgba($color: #ffffff, $alpha: 0.5);
        color: #003348;
        text-align: center;
    }
    td{
        vertical-align: middle;
    }
}


.btnClass {
    background-color: #d9e255;
}

.userSelect-table{
    max-height : 400px;
    overflow-y: auto;
}