@import 'variables';

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: auto;
}

* {
    box-sizing: border-box !important;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc !important;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ccc !important;
}

:-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ccc !important;
}

.text-info {
    color: #333 !important;
    font-size: 12px !important;
    text-align: center !important;
    padding: 18px !important;
}

.text-primary {
    color: $primary;
}

.text-error {
    color: $error;
}

.text-success {
    color: $success;
}

.cursor-pointer {
    cursor: pointer;
}

.caption {
    font-size: 12px;
}

.link-button {
    cursor: pointer;
}

.link-button:hover {
    text-decoration: underline;
}


.container-page {
    position: absolute;
    top: 0;
    bottom: $footer-height;
    left: 0;
    right: 0;
    cursor: default;
    z-index: 1;
    background: url(../assets/image/Wallpaper_Power_BI.jpg) bottom center no-repeat;
    background-size: cover;

    .logoPW {
        position: absolute;
        top: 15%;
        max-width: 400px;
        max-height: 100px;
        width: auto;
        height: auto;
        transform: translate(-50%, -50%);
        left: 50%;
    }

    .logoPCA {
        position: fixed;
        top: 82%;
        max-width: 100px;
        max-height: 50px;
        width: auto;
        height: auto;
        transform: translate(-50%, -50%);
        left: 50%;
    }
}

.container-page-dashboard {
    position: absolute;
    top: 0;
    bottom: $footer-height;
    left: 0;
    right: 0;
    cursor: default;
    z-index: 1;
}

.button-danger {
    background-color: $error !important;
    border: 1px solid $error !important;
}

.padding-header {
    top: $app-bar-height !important;
}

// Animation between pages
.fade-appear .fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-appear-active .face-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 700ms linear 300ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms linear;
}

.text-center {
    text-align: center;
}

.k-icon-input {
    color: #999;
}

.cho-containner {
    border-color: #007abc !important;
    color: #ffffff !important;
    background-color: #007abc !important;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.04)) !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 14px !important; 
}


// Divisor de conteúdos
.divider {
    width: 100%;
    height: 1px;
    margin: 0px auto;
    background-color: rgba(0, 0, 0, 0.3);
}