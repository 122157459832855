.notification-group {
    left: 50%;
    bottom: 40px;
    transform: translate(-50%, 0);
    align-items: center;
    justify-content: center;
    flex-wrap: wrap-reverse;
    width: 100vw;
    padding: 4px;
    z-index: 999999;

    .notification {
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 45px;
        border-radius: 2px;
        width: 100%;
        max-width: 520px;
    }
}