@import '../../styles/variables';

.text-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 13px !important;
    padding: 20px 20px 12px 20px !important;

    span, a {
        padding: 2px 0px;
    }

    .blue-text {
        color: #0277bd;
    }

    .cursor-pointer {
        cursor: pointer;
    }
}