@import '../../../styles/variables';

.btn-primary {
    button {
        color: #fff;
        background-color: $primary;
        font-size: 14px;
        line-height: 1.42857;
        font-family: inherit;
        text-align: center;
        white-space: nowrap;
        padding: 4px 8px;
        border-width: 1px;
        border-style: solid;
        border-radius: 2px;
    }
    button:hover {
        background-color: #ebebeb;
        color: #333;
        border: 0;
    }
}