@import '../../styles/variables';

.buttonPositions{
    display: flex;
    justify-content: center;
    Button:first-child{
        margin-right: 10px;
    }
}

.link_subscription{
    font-weight: 900;
    font-size: 12px !important;
    padding: 0;
}
.text-subscription{
    color: #00263c !important;
    font-weight: 500;
    font-size: 12px !important;
    text-align: center !important;
    padding: 18px !important;
}