.pv-alertdialog {
    .k-header {
        background-color: #333333;
        color:#d9e255;
        font-weight: 500;
    }

    .k-window-content, .k-dialog-buttongroup {
        background-color: #ccd7dc;
    }

    .k-dialog-content {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .pv-alertdialog-buttoncancel {
        width: 100%
    }

    .pv-alertdialog-actioncontents {
        display: flex;
        justify-content: space-evenly;
    }

    .title-container {
        width: 87%;
        background-color: #333333;
        padding: 12px;
        color: #ffffff
    }

    .title-square-detail {
        width: 13%;
        background-color: #d9e255;
    }

    .main-modal-header {
        font-family: 'Leelawadee UI', Georgia, 'Times New Roman', Times, serif;
        font-size: 1.5rem;
    }

    .main-modal-content {
        background-color: rgba(255, 255, 255,0.8);
        padding: 0 14px 14px 14px;
    }
}
